import React from 'react';
import PageWithHeader from '../components/pageWithHeader';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { richTextOptions } from '../helpers/contentfulHelpers';
import style from './privacy.module.scss';
import { DataComponent } from '../helpers/dataHelpers';

const Privacy = () => {
  const data = useStaticQuery<GatsbyTypes.PrivacyQuery>(graphql`
    query Privacy {
      pageImage: file(name: {eq: "privacy"}) {
        publicURL
      }
      allContentfulPages(filter: {slug: {eq: "privacy-policy"}, node_locale: {eq: "en-US"}}) {
        nodes {
          name
          slug
          content {
            json
          }
        }
      }
    }
  `);

    return <PrivacyInner data={data}></PrivacyInner>
};

export default Privacy;

const PrivacyInner:DataComponent<GatsbyTypes.PrivacyQuery> = ({data}) => {
  return (
    <PageWithHeader
      title="Privacy Policy"
      description="Privacy policy for https://centeva.com"
      image={data?.pageImage?.publicURL}
    >
      <article className={style.mainArticle}>
        {documentToReactComponents(data?.allContentfulPages?.nodes[0]?.content?.json as any, richTextOptions({ edges: [] }))} 
      </article>
    </PageWithHeader>
  );
}